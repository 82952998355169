<template>
  <div>
    <div class="SpacerBlock" :style="`${orientation}:${spacing}px`" />
  </div>
</template>

<script>
export default {
  name: 'SpacerBlock',
  props: {
    orientation: {
      type: String,
      default: 'h'
    },
    spacing: {
      type: String,
      default: '2'
    }
  }
}
</script>

<style>

</style>
